import React from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import Logo from "../../assets/img/SCUIDO LOGO.png";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import {userConst} from "../../user/userConsts";
import {isEmpty} from "../../utils/validations";
import {AppBar, Toolbar} from "@mui/material";
import appTheme from "../../assets/appTheme";
import DLText from "dlcomponent/components/DLText/DLText";
import {getFormattedUserName, getRandomColors, isWhatsAppEnabled} from "../../utils/helper";
import AccountOverviewIcon from "../../assets/img/AccountOverviewIcon.png";
import billingInvoiceIcon from "../../assets/img/billingInvoiceIcon.png"
import supportIcon from "../../assets/img/supportIcon.png"
import virtualInboxIcon from "../../assets/img/virtualInboxIcon.png"
import userManagementIcon from "../../assets/img/userManagementIcon.png"
import settings from "../../assets/img/settings.png"
import {setSwitchButton} from "../../accountManagement/accountManagementActions";

const ResAppBarView = (props) => {
    const {handleItemOpen, handleItemClose, handleMenuItemsOpen, anchorEl, isMobileView, account, isXSView} = props;

    const renderSettingMenu = () => {
        const {account, handleSettingIconClick, anchorSettings, handleMenuItemsClose, isLoggedOutScreen} = props;


        if (userAuthStatus === userConst.AUTH_STATUS_UNAUTHORIZED || isEmpty(account) || isLoggedOutScreen) {
            return;
        }

        let menuOptions = [
            {
                label: 'Account Overview',
                isDisabled: checkReadOnlyAccess('AccountOverview'),
                icon: <img src={AccountOverviewIcon}
                          width={'14px'}
                          height={'14px'}
                          className='mt-1 me-3'
                          alt={''}/>,
                onClick: () => {
                    props.history.push('/accounts/' + account.id + '/overview');
                },
                isVisible: true,
            },
            {
                label: 'User Management',
                isDisabled: false,
                icon: <img src={userManagementIcon}
                          width={'14px'}
                          height={'14px'}
                          className='mt-1 me-3'
                          alt={''}/>,
                onClick: () => {
                    setSwitchButton('User');
                    props.history.push('/accounts/' + account.id + '/users-roles-groups');
                },
                isVisible: true,
            },
            {
                label: 'Billing & Invoice',
                isDisabled: checkReadOnlyAccess('BillingInvoice'),
                icon: <img src={billingInvoiceIcon}
                           width={'14px'}
                           height={'14px'}
                           className='mt-1 me-3'
                           alt={''}/>,
                onClick: () => props.history.push('/accounts/' + account.id + '/billing'),
                isVisible: true,
            },
            {
                label: 'Support',
                isDisabled: false,
                icon: <img src={supportIcon}
                           width={'14px'}
                           height={'14px'}
                           className='mt-1 me-3'
                           alt={''}/>,
                onClick: () => props.history.push('/user/contact-us', {accountId: account?.id}),
                isVisible: true,
            },
            {
                label: 'Settings',
                isDisabled: checkReadOnlyAccess('Settings'),
                icon: <img src={settings}
                           width={'14px'}
                           height={'14px'}
                           className='mt-1 me-3'
                           alt={''}/>,
                onClick: () => props.history.push('/accounts/' + account?.id + '/settings/sender-emails'),
                isVisible: true,
            },
            {
                label: 'Virtual Inbox',
                isDisabled: false,
                icon: <img src={virtualInboxIcon}
                           width={'14px'}
                           height={'14px'}
                           className='mt-1 me-3'
                           alt={''}/>,
                onClick: () => props.history.push('/accounts/' + account?.id + '/virtual-inbox'),
                isVisible: true,
            },
            // {
            //     label: 'WhatsApp Console',
            //     isDisabled: false,
            //     icon:  <i className="fa-brands fa-whatsapp mt-1 me-3 whatsapp-menu-option"
            //              style={{fontSize: 15}}
            //     />,
            //     onClick: () => props.history.push('/accounts/' + account?.id + '/whatsapp-console'),
            //     isVisible: true,
            // },
        ];

        let icon = (
            <div className='ms-lg-2 ms-xl-3 ms-1  mt-1 cursor'
                 onClick={handleMenuItemsOpen}>
                <i className='fa fa-cog icon-size default-icon' style={{fontSize: 20}}/>
            </div>
        );

        let menu = (
            <Menu
                id="items-menu"
                anchorEl={anchorSettings}
                getcontentanchorel={null}
                anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                transformOrigin={{vertical: "top", horizontal: "right"}}
                open={Boolean(anchorSettings)}
                onClose={handleMenuItemsClose}
                className="p-2"
                style={{
                    top: '12px'
                }}
                disableAutoFocusItem
                PaperProps={{
                    style: {
                        marginLeft: '0px'
                    }
                }}
                MenuListProps={{
                    style: {
                        paddingTop: 6,
                        paddingBottom: 6,
                        color: '#0B407D'
                    },
                }}>

                {menuOptions.map((item, index) => {

                    if (item?.isVisible) {
                        return (
                            <MenuItem key={'profile-menuItem-' + index}
                                      className={item?.isDisabled ? 'dropdownMenuDisable' : 'cursor dropdownMenu'}
                                      onClick={() => {
                                          if (!item?.isDisabled) {
                                              handleMenuItemsClose();
                                              item?.onClick()
                                          }
                                      }}>
                                {item?.icon}
                                {item?.label}
                            </MenuItem>
                        )
                    }

                    return <span key={'profile-menuItem-' + index}/>

                })}
            </Menu>
        );


        return (
            <div onClick={() => {
                handleSettingIconClick(false)
            }}>
                {icon}
                {menu}
            </div>
        );

    };

    const getFormattedRole = (role) => {
        if (role === "OWNER"){
            return "Owner"
        }else if (role === "VIEWER"){
            return "Viewer"
        }else if (role === "ADMIN"){
            return "Admin"
        }else if (role === "ANALYST"){
            return "Analyst"
        }else if (role === "IT"){
            return "IT"
        }else if (role === "MARKETING"){
            return "Marketing"
        }else if (role === "DESIGNER"){
            return "Designer"
        }
    };

    const renderNameAndIcon = () => {
        const {profile, action} = props;

        let profileImage = profile?.firstName?.slice(0, 1) + profile?.lastName?.slice(0, 1);

        return (
            <div className='d-flex p-3 align-items-center'>
                <Avatar className='avatar-img-lg p-3' style={{backgroundColor: appTheme.appColor.warning}}>
                    {profileImage?.toUpperCase()}
                </Avatar>
                <div className='ms-2'>
                    <div>
                        <DLText id={''}
                                text={getFormattedUserName(profile)}
                                fontWeight={"semi-bold"}
                                marginBottom={'none'}
                        />
                    </div>
                    <div>
                        <DLText id={''}
                                text={profile?.email}
                                fontWeight={"semi-bold"}
                                fontColor={'gray'}
                                marginBottom={'none'}
                        />
                    </div>
                    {!isEmpty(account?.memberRole?.name) &&
                    <span className="role-badge p-1">
                        {getFormattedRole(account?.memberRole?.name)}
                    </span>
                    }
                </div>
            </div>
        )
    };

    const renderProfileMenu = () => {

        const {profile, account, handleSettingIconClick} = props;

        if (userAuthStatus === userConst.AUTH_STATUS_UNAUTHORIZED || isLoggedOutScreen) {
            return;
        }

        let profileImage = profile?.firstName?.slice(0, 1) + profile?.lastName?.slice(0, 1);

        let menuOptions = [
            {
                label: 'My Profile',
                onClick: () => props.history.push('/user/profile'),
                isVisible: true,
            },
            {
                label: 'Change Password',
                onClick: () => props.history.push('/user/change-password'),
                isVisible: true
            },
            {
                label: 'Logout',
                onClick: () => {
                    if(props.location.pathname === `/accounts/${account?.id}/contents/create` || props.location.pathname === `/accounts/${account?.id}/quick-start` || props.location.pathname.includes(`/accounts/${account?.id}/segment/`)){    // this if statement {} is specially written for the log out action from data setup specially to avoid logout action method first
                        props.history.push('/logoutAction');
                    }else{
                        props.logoutUser(account?.id);
                        props.history.push('/');
                    }
                },
                isVisible: true
            },

        ];

        let icon = (
            <div className='ms-lg-2 ms-xl-3 ms-2 cursor'
                 onClick={handleItemOpen}>
                <Avatar className='avatar-img p-3'
                        style={{backgroundColor: getRandomColors(profile?.firstName)}}>
                    {profileImage?.toUpperCase()}
                </Avatar>
            </div>
        );

        let menu = (
            <Menu
                id="items-menu"
                anchorEl={anchorEl}
                getcontentanchorel={null}
                anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                transformOrigin={{vertical: "top", horizontal: "right"}}
                open={Boolean(anchorEl)}
                onClose={handleItemClose}
                className="p-2"
                style={{
                    top: '11px'
                }}
                disableAutoFocusItem
                PaperProps={{
                    style: {
                        marginLeft: '0px'
                    }
                }}
                MenuListProps={{
                    style: {
                        color: '#0B407D'
                    },
                }}>
                <div>
                    {renderNameAndIcon()}
                </div>
                {menuOptions.map((item, index) => {

                    if (item?.isVisible) {
                        return (
                            <MenuItem key={'profile-menuItem-' + index} className="dropdownMenu d-block mx-2"
                                      onClick={() => {
                                          handleItemClose();
                                          item.onClick();
                                      }}>
                                {item?.label}
                            </MenuItem>
                        )
                    }

                    return <span key={'profile-menuItem-' + index}/>

                })}

            </Menu>
        );


        return (
            <div onClick={() => {
                handleSettingIconClick(false)
            }}>
                {icon}
                {menu}
            </div>
        );
    };

    const renderAppLogo = () => {

        return (
            <div className='d-flex'>
                {/*<div className='d-flex justify-content-center align-items-center text-center beta-chip'>*/}
                {/*   BETA*/}
                {/*</div>*/}
                <div className="logo-alignment ms-0">
                    <img src={Logo}
                         onClick={() => {
                             if (isEmpty(account?.id)) {
                                 props.history.push('/accounts/dashboard')
                             } else {
                                 props.history.push('/accounts/' + account?.id + '/dashboard')
                             }
                         }}
                         style={{height: '35px', marginLeft:'6px'}}
                         className='img-fluid  py-1 cursor ms-0'
                         alt={''}/>
                {/*<DLText id={''}*/}
                {/*        isClickable={true}*/}
                {/*        fontWeight={"semi-bold"}*/}
                {/*        text={'SCuiDO'}*/}
                {/*        customTextStyle={{*/}
                {/*            fontSize: 28,*/}
                {/*            color: appTheme.appColor.primary,*/}
                {/*        }}*/}
                {/*       */}
                {/*/>*/}
                </div>
            </div>

        );
    };

    const checkReadOnlyAccess = (buttonName) => {
        const {account} = props;
        let memberRole = account?.memberRole;

        if (isEmpty(memberRole)) {
            return false
        }

        switch (memberRole?.name) {
            case 'IT'://campaign, segment, email template, account overview, billing and invoice, subscritpion
                return buttonName === 'Campaigns' || buttonName === 'Segments' || buttonName === 'Templates' ||
                    buttonName === 'AccountOverview' || buttonName === 'BillingInvoice' || buttonName === 'Subscription';
            case 'ANALYST'://campaign ,  setting, email templates, account overview, subscription, billing and invoice
                return buttonName === 'Campaigns' || buttonName === 'Templates' || buttonName === 'DataSetup' ||
                    buttonName === 'AccountOverview' || buttonName === 'BillingInvoice' || buttonName === 'Subscription' || buttonName === 'Settings';
            case 'DESIGNER': //campaign, segment, data setup, account overview, billing and invoice, setting, subscription
                return buttonName === 'Campaigns' || buttonName === 'Segments' || buttonName === 'DataSetup' ||
                    buttonName === 'AccountOverview' || buttonName === 'BillingInvoice' || buttonName === 'Subscription' || buttonName === 'Settings';
            case 'MARKETING'://account overview, billing and invoice, subscritpion
                return buttonName === 'AccountOverview' || buttonName === 'BillingInvoice' || buttonName === 'Subscription';
            case 'VIEWER'://account overview, billing and invoice, subscritpion
                return buttonName === 'BillingInvoice';
            case 'ADMIN'://account overview, billing and invoice, subscritpion
                return buttonName === 'BillingInvoice';
            default:
                return false
        }

    };

    const getAccountName = () => {

        const {account} = props;

        if (isEmpty(account?.name)) {
            return 'Choose Account'
        }
        if (isXSView) {
            return account?.name.slice(0, 11) + "...";
        }
        if (account?.name.length > 22) {
            return account?.name.slice(0, 22) + "...";
        }
        return account?.name;


    };

    const getMenuOptions = () => {
        const {account, refreshWindowScrollElement} = props;
        return [
            {
                label: 'Home',
                isDisabled: false,
                onClick: () => {
                    refreshWindowScrollElement()
                },
                route: isEmpty(account?.id) ? '/accounts/dashboard' : '/accounts/' + account?.id + '/dashboard',
                icon: (<i className={'fas fa-briefcase '}/>),
            },
            {
                label: 'Campaigns',
                isDisabled: checkReadOnlyAccess('Campaigns'),
                onClick: () => {
                    refreshWindowScrollElement();
                    props.resetQuickStart()
                },
                route: '/accounts/' + account?.id + '/campaigns',
                icon: (<i className={'fas fa-bullhorn'}/>),
            },
            {
                label: 'Segments',
                isDisabled: checkReadOnlyAccess('Segments'),
                onClick: () => {
                    refreshWindowScrollElement();
                    props.refreshSegmentsData()
                },
                route: '/accounts/' + account?.id + '/segments',
                icon: (<i className={'fas fa-server'}/>),
            },


            {
                label: isWhatsAppEnabled() ? 'Templates': 'Email Templates',
                isDisabled: checkReadOnlyAccess('Templates'),
                icon: (<div className={'bullet-dot'}
                            style={{marginRight: '14px'}}/>),
                route: '/accounts/' + account?.id +  (isWhatsAppEnabled() ? '/templates' :'/email-templates'),
                onClick: () => {
                    refreshWindowScrollElement();
                    props.resetEmailTemplateFilter()
                }
            },

            {
                label: 'Content',
                section: 'CustomerData',
                isDisabled: checkReadOnlyAccess('DataSetup'),
                icon: (<i className={' fas fa-user '}/>),
                route: '/accounts/' + account?.id + '/contents',
                onClick: () => {
                    refreshWindowScrollElement()
                }
            },

        ];
    };

    const getRoute = (item) => {

        if (item?.isDisabled) {
            return props.currentPathname
        }
        return item?.route
    };

    const renderNavigatorWithOptions = () => {
        const {account, isLoggedOutScreen} = props;

        if (userAuthStatus === userConst.AUTH_STATUS_UNAUTHORIZED || isEmpty(account) || isLoggedOutScreen) {
            return;
        }

        return (
            <div className='d-flex'>
                {getMenuOptions().map((item, index) => {

                    return (
                        <NavLink to={getRoute(item)}
                                 key={index}
                                 className={item?.isDisabled ? 'resAppBarMenuOptionsDisable py-2 headerOptionPadding' : 'resAppBarMenuOptions py-2 headerOptionPadding'}
                                 activeClassName={item?.isDisabled ? '' : "activeMenuOption "}>

                            <div key={'section-' + index}
                                 style={{
                                     opacity: '100%',
                                     paddingTop: 3,
                                     paddingBottom: 3,
                                     // color:'#333333',
                                 }}
                                 className={' border-rounded'}
                                 onClick={() => {
                                     if (!item?.isDisabled) {
                                         item?.onClick()
                                     }
                                 }}>
                                {item?.label}
                            </div>
                        </NavLink>
                    );
                })}
            </div>
        )
    };

    const renderAccountName = () => {
        const {isXSView, account, showChooseAccount, isLoggedOutScreen, isMobileView, accounts} = props;
        let ignorePath = ['/subscription/payment/razarpay'];

        if ((userAuthStatus === userConst.AUTH_STATUS_UNAUTHORIZED || isLoggedOutScreen || ignorePath.includes(props.currentPathname))) {
            return;
        }

        if (!showChooseAccount) {
            return;
        }

        return (
            <div className='fw-bold cursor border px-lg-3 px-2 d-flex justify-content-between align-items-center resAppBarAccountsDropdown'
                onClick={() => {
                    props.history.push('/accounts')
                }}
                style={{
                    width: isXSView ? 150 : 207,
                    paddingTop: 5,
                    paddingBottom: 5,
                    height: 36,
                    borderRadius: 7,
                    borderWidth: 2
                }}>

                <div className={'text-truncate pe-2'}>
                    <DLText id={''}
                        text={getAccountName(account)}
                            fontSize={"md"}
                            fontColor={"primary"}
                    />
                </div>
                <div>
                    <i className="fa-solid fa-caret-down active-icon"/>
                </div>
            </div>
        )
    };

    const authorizedContentUi = () => {
        const {isXLView, handleSettingIconClick} = props;

        return (
            <div className="d-flex justify-content-between w-100 " style={{height: "100%"}}>

                <div className='d-flex d-lg-none align-items-center ' onClick={() => {
                    handleSettingIconClick(false)}}>
                    {renderAppLogo()}
                </div>
                <div className='d-lg-flex d-none align-items-center'
                     style={{width: '70%'}}
                     onClick={() => {
                         handleSettingIconClick(false)
                     }}>

                    <div className={''}
                         style={{marginRight: isXLView ? 70 : 20}}>
                        {renderAppLogo()}
                    </div>

                    <div className={""}>
                        {renderNavigatorWithOptions()}
                    </div>

                </div>

                <div className='d-flex align-items-center flex-shrink-0'>
                    {renderAccountName()}

                    <div className='d-lg-flex d-none'>
                        {renderSettingMenu()}
                    </div>
                    {/*user avatar*/}
                    {renderProfileMenu()}
                </div>
            </div>
        );

    };

    const unauthorizedContentUi = () => {
        const {isXSView, account} = props;

        return (
            <div className="d-flex justify-content-between w-100">
                <div className="d-flex align-items-center">
                    <img src={Logo}
                         onClick={() => {
                             props.logoutUser(account?.id);
                             props.history.push('/');
                         }}
                         style={{height: '35px', marginLeft: isXSView ? 0 : '6px'}}
                         className='img-fluid  py-1 cursor'
                         alt={''}/>
                </div>
            </div>
        );
    };

    const {userAuthStatus, isLoggedOutScreen} = props;

    if (props.currentPathname === '/session-expired') {
        return (
            <div className="res-app-bar-header">
            <AppBar drawerwidth={0}
                    position="fixed"
                    open={true}
                    className='text-white shadow-none d-flex app-bar'>
                <Toolbar className='resAppBarContainer' style={{height: '54px'}}>
                    {unauthorizedContentUi()}
                </Toolbar>
            </AppBar>
            </div>
        );
    }
    if (userAuthStatus === userConst.AUTH_STATUS_AUTHORIZED) {
        return (
            <div className="res-app-bar-header">
                <AppBar drawerwidth={0}
                        position="fixed"
                        open={true}
                        className='text-white shadow-none d-flex app-bar'>
                    <Toolbar className='resAppBarContainer toolbar-header' style={{
                        height: '54px',
                        paddingLeft: isMobileView ? 15 : 30,
                        paddingRight: isMobileView ? 15 : 30
                    }}>
                        {authorizedContentUi()}
                    </Toolbar>
                </AppBar>
            </div>
        )
    }

    return <></>
};

ResAppBarView.propTypes = {};


export default (withRouter(ResAppBarView))


